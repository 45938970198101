<template>
  <div>
    <div class="row content-row" v-if="noData">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12 vlt-contents vlt-display">
        <span class="no-data">{{noData}}</span>
      </div>
    </div>
    <div class="row content-row" v-else-if="contents" ref="contentsBlock">
      <div
        v-for="(content, i) in contents"
        :key="i"
        class="col-md-4 col-lg-4 col-xlg-4 col-sm-12 col-xs-12 vlt-contents"
      >
        <content-card
          :content="content"
          :orientation="orientation"
          :showContentDetailEvent=" () => showDetail(content)"
        />
      </div>
    </div>
    <app-loader v-show="showLoading"></app-loader>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";
import {checkEventEnded} from '@/mixins/dateSupport.js';

export default {
  data() {
    return {
      contents: [],
      orientation: "LANDSCAPE",
      noData: null,
      showLoading: false,
      flagAutologin:true,
      page:1,
      observer:null
    };
  },
  props: ["purchasedDetails","purchasedParent"],
  watch: {
    purchasedDetails(val) {
      if (val.length > 0) {
        this.showLoading = false;
        this.contents = this.purchasedDetails;
        this.attachObserver();
      } else {
        this.showLoading = false;
        this.contents = [];
        this.noData = "No active purchases found";
      }
    }
  },
  methods: {
    ...mapActions(["actGetTicket"]),
    showDetail(content) {
      this.detailcontent = content;
      EventBus.$emit("CloseFeaturedDetail");
    },
    fetchContents(){
      this.page++;
      let page=this.page;

      this.actGetTicket({page})
        .then(response => {
          console.log("tickets response ", response);
          if(response && response.error)return;
          let newContents=[];
          response.forEach(element => {
            if (element.status === "ACTIVE" && !checkEventEnded(element.expiry)) {
                newContents.push(element);
            }
          });
          console.log("with added new contents ", newContents);
          this.contents.push(...newContents);
        })
        .catch(error => {
          console.log(error);
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.getPurchasedDetails);
              this.flagAutologin = false;
              return;
            }
          }
        });
    },
    attachObserver(){
        this.$nextTick(()=>{
            if(this.$refs.contentsBlock){
              
                let parent=this.$refs.contentsBlock;
                let options={
                  root:this.purchasedParent,
                  threshold:0
                }

                this.observer=new IntersectionObserver((entries,observer)=>{
                      if(entries[0].isIntersecting){
                        this.fetchContents();
                        observer.disconnect();
                      }
                },options);

                let lastchild=parent.children && parent.children[parent.children.length-1];
                if(lastchild && lastchild instanceof HTMLElement){
                  this.observer.observe(lastchild);
                }

            }
        })
    }
  },
  components: {
    "content-card": () =>
      import(
        /* webpackChunkName: "contentCard" */ "@/components/Profile/purchasedContentChild.vue"
      ),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      )
  },
  mixins: [appMixins]
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
.vlt-display {
  text-align: center;
}
.vlt-contents {
  margin-bottom: 20px;
  height: 300px;
}

.row{
  margin: 0px
}
</style>